export const MALE_FRONT_AREAS = [
  {
    id: 1,
    title: "Front Left Head",
    coords:
      "827,12,766,28,723,68,716,129,711,208,699,193,692,197,689,235,698,275,714,284,726,330,765,355,826,359",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 2,
    title: "Front Right Head",
    coords:
      "827,13,827,359,873,358,931,324,939,281,965,261,964,189,944,203,943,122,934,84,909,41,875,23,852,19",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 3,
    title: "Front Neck",
    coords:
      "729,338,756,357,799,360,854,362,891,353,928,327,921,437,977,489,1042,528,867,543,842,568,815,568,793,544,612,532,737,436",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 4,
    title: "Front Left Shoulder",
    coords:
      "827,571,808,568,787,547,606,532,538,532,475,562,422,641,408,722,555,828,826,834",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 5,
    title: "Front Right Shoulder",
    coords:
      "829,570,849,569,864,548,1051,532,1133,535,1189,576,1237,647,1250,717,1100,840,830,837",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 6,
    title: "Front Left Upper Arm",
    coords:
      "544,823,548,887,529,933,484,1028,471,1065,358,1001,377,936,396,853,413,808,410,727",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 7,
    title: "Front Right Upper Arm",
    coords:
      "1109,837,1105,881,1121,914,1161,982,1187,1055,1304,991,1247,798,1252,719",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 8,
    title: "Front Left Lower Arm",
    coords:
      "469,1071,463,1105,450,1141,411,1211,374,1275,330,1334,284,1396,257,1457,163,1414,202,1343,244,1248,264,1147,287,1095,316,1050,352,1001",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 9,
    title: "Front Right Lower Arm",
    coords:
      "1191,1059,1305,993,1383,1097,1406,1169,1415,1218,1504,1404,1411,1451,1390,1396,1308,1293,1256,1209,1206,1123",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 10,
    title: "Front Left Hand",
    coords:
      "165,1411,258,1460,255,1494,249,1523,238,1566,224,1610,223,1694,218,1712,202,1715,190,1618,184,1629,180,1656,154,1740,138,1748,129,1734,140,1691,153,1618,135,1629,128,1651,108,1699,101,1728,80,1739,71,1730,79,1693,110,1601,101,1599,86,1626,52,1684,36,1688,28,1670,67,1593,88,1541,88,1523,74,1537,43,1563,27,1561,24,1549,49,1517,62,1494,82,1477,114,1455,144,1446",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 11,
    title: "Front Right Hand",
    coords:
      "1411,1457,1506,1407,1522,1437,1567,1457,1593,1477,1613,1512,1631,1533,1636,1548,1626,1554,1605,1548,1583,1523,1587,1542,1593,1563,1613,1598,1633,1643,1638,1667,1631,1679,1616,1670,1570,1594,1568,1609,1582,1635,1604,1717,1598,1731,1577,1722,1538,1618,1527,1610,1524,1622,1531,1655,1543,1705,1547,1741,1531,1744,1519,1731,1506,1673,1495,1621,1485,1613,1483,1641,1479,1677,1479,1701,1478,1711,1466,1711,1455,1705,1455,1650,1454,1610,1437,1567,1421,1517",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 12,
    title: "Left Chest",
    coords:
      "826,837,827,938,759,966,708,1001,691,1018,688,1037,674,1107,655,1148,625,1192,581,1233,593,1144,594,1049,581,1004,560,923,551,862,551,828",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 13,
    title: "Right Chest",
    coords:
      "829,838,832,941,884,958,916,970,939,988,959,1015,971,1031,971,1061,976,1107,985,1143,1016,1183,1042,1206,1078,1232,1071,1171,1060,1120,1065,1033,1081,973,1097,908,1105,840",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 14,
    title: "Left Abdomen",
    coords:
      "573,1313,631,1364,671,1407,830,1408,833,947,797,952,741,979,716,999,693,1022,679,1107,637,1181,582,1235",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 15,
    title: "Right Abdomen",
    coords:
      "836,948,833,1407,985,1405,1081,1306,1075,1232,1011,1181,982,1138,970,1091,967,1028,922,976,882,961",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 16,
    title: "Genitalia",
    coords:
      "670,1410,985,1410,912,1500,879,1601,851,1618,808,1621,775,1600,742,1499",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 17,
    title: "Front Left Thigh",
    coords:
      "573,1320,669,1410,724,1473,764,1577,778,1608,813,1632,813,1733,783,1858,750,1965,731,2092,539,2068,537,1991,521,1913,514,1790,514,1627,537,1514",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 18,
    title: "Front Right Thigh",
    coords:
      "1085,1318,1031,1360,927,1473,898,1551,879,1608,846,1627,853,1736,882,1837,910,1913,938,2005,964,2102,1149,2059,1153,1953,1160,1849,1153,1669,1139,1568,1111,1445",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 19,
    title: "Left Shin",
    coords:
      "523,2241,480,2366,478,2468,495,2675,497,2815,591,2819,589,2746,615,2654,646,2567,672,2477,672,2366,676,2286",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 20,
    title: "Right Shin",
    coords:
      "1028,2295,1033,2338,1033,2385,1038,2456,1054,2531,1087,2605,1123,2699,1137,2770,1146,2808,1236,2805,1229,2704,1231,2600,1236,2486,1231,2399,1217,2335,1179,2234",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 21,
    title: "Front Left Foot",
    coords:
      "588,2811,600,2847,606,2871,595,2892,594,2923,594,2947,591,2967,579,2993,590,3033,578,3067,567,3085,517,3098,413,3070,411,3033,443,2993,472,2944,483,2898,474,2866,490,2819,554,2825",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 22,
    title: "Front Right Foot",
    coords:
      "1142,2807,1163,2817,1200,2808,1228,2805,1240,2803,1246,2816,1252,2840,1253,2868,1250,2902,1265,2936,1288,2972,1305,2991,1329,3034,1320,3064,1197,3095,1160,3058,1146,3030,1151,2982,1136,2906,1127,2862",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 49,
    title: "Left Knee",
    coords: "537,2071,523,2239,683,2286,717,2196,731,2094",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 50,
    title: "Right Knee",
    coords: "957,2104,972,2172,1021,2295,1177,2231,1153,2059",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
];

export const MALE_BACK_AREAS = [
  {
    id: 23,
    title: "Back Left Head",
    coords:
      "846,296,782,299,744,299,720,263,706,223,703,183,708,172,726,184,729,153,735,92,754,50,794,22,849,10",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 24,
    title: "Back Right Head",
    coords:
      "851,14,848,297,902,297,960,297,984,260,994,203,1000,176,987,168,975,195,975,137,961,78,924,31,894,16",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 25,
    title: "Back Neck",
    coords:
      "742,300,955,297,957,320,951,347,948,387,958,411,1010,440,1054,463,1094,487,1148,503,539,503,593,490,656,463,711,434,750,396,756,363,745,336",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 26,
    title: "Back Left Shoulder",
    coords:
      "846,505,848,685,727,744,705,755,617,765,548,755,398,733,398,671,410,631,428,585,468,545,495,521,538,500",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 27,
    title: "Back Right Shoulder",
    coords:
      "848,503,1155,502,1216,528,1241,552,1259,573,1274,601,1295,658,1301,695,1301,722,1155,761,1110,762,1031,758,981,744,849,683",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 28,
    title: "Back Left Upper Arm",
    coords:
      "542,756,548,815,544,861,528,898,504,934,495,950,471,1004,458,1050,413,1068,358,1050,334,998,341,973,362,904,382,843,402,788,398,733",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 29,
    title: "Back Right Upper Arm",
    coords:
      "1156,762,1148,832,1161,883,1180,925,1204,962,1222,1005,1234,1050,1286,1068,1335,1053,1359,1005,1355,979,1331,895,1303,810,1295,779,1301,722",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 30,
    title: "Back Left Lower Arm",
    coords:
      "456,1053,410,1068,358,1050,332,993,295,1026,271,1069,253,1116,240,1159,230,1207,218,1251,203,1297,186,1362,167,1420,149,1447,240,1493,243,1458,286,1384,341,1311,402,1201,443,1131,449,1090",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 31,
    title: "Back Right Lower Arm",
    coords:
      "1237,1052,1288,1069,1334,1053,1362,996,1387,1019,1404,1046,1428,1087,1447,1142,1455,1181,1472,1244,1480,1294,1493,1341,1510,1390,1526,1429,1541,1457,1443,1500,1444,1469,1416,1409,1373,1347,1323,1275,1285,1198,1256,1147,1244,1119",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 32,
    title: "Back Left Hand",
    coords:
      "148,1445,241,1494,240,1554,225,1615,215,1648,215,1694,215,1745,207,1760,197,1757,189,1707,180,1658,176,1694,164,1749,161,1779,143,1782,137,1773,146,1675,142,1648,134,1672,127,1704,118,1754,104,1767,89,1762,101,1681,106,1652,80,1713,70,1716,63,1706,67,1670,75,1633,85,1599,70,1590,79,1575,79,1554,85,1539,89,1521,110,1496,130,1485",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 33,
    title: "Back Right Hand",
    coords:
      "1443,1497,1539,1455,1556,1488,1578,1505,1607,1530,1611,1552,1615,1575,1629,1594,1624,1606,1602,1606,1605,1625,1618,1676,1624,1718,1623,1727,1608,1727,1572,1642,1578,1673,1586,1719,1587,1754,1587,1770,1572,1771,1562,1752,1542,1667,1532,1660,1535,1692,1536,1746,1539,1783,1528,1788,1514,1782,1502,1698,1496,1664,1490,1685,1481,1730,1478,1754,1472,1761,1462,1757,1459,1727,1462,1692,1465,1652,1440,1554",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 34,
    title: "Upper Left Back",
    coords:
      "848,686,730,744,694,759,638,762,574,761,547,753,548,803,556,880,572,940,601,1034,680,1032,766,1002,846,955",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 35,
    title: "Upper Right Back",
    coords:
      "849,685,848,959,920,1001,1015,1019,1103,1014,1131,937,1143,852,1153,762,1089,762,1024,759,984,744",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 36,
    title: "Lower Left Back",
    coords:
      "847,956,850,1189,727,1294,676,1284,640,1287,622,1291,608,1300,584,1317,587,1259,588,1217,605,1135,606,1078,602,1035,678,1032,770,999",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 37,
    title: "Lower Right Back",
    coords:
      "848,959,851,1193,979,1303,1025,1296,1058,1297,1088,1303,1119,1327,1112,1263,1113,1229,1103,1183,1097,1138,1095,1087,1095,1052,1104,1014,1018,1020,917,1001",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 38,
    title: "Left Hip",
    coords:
      "846,1429,818,1371,785,1336,744,1306,718,1294,680,1283,627,1289,596,1309,583,1324,572,1372,562,1442,554,1497,542,1549,525,1609,532,1627,553,1657,574,1679,595,1698,627,1719,669,1733,729,1737,773,1724,823,1685,851,1616",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 39,
    title: "Right Hip",
    coords:
      "849,1433,905,1350,975,1300,1033,1294,1069,1297,1088,1306,1119,1332,1136,1403,1142,1455,1150,1517,1158,1569,1167,1607,1153,1634,1140,1655,1124,1682,1079,1715,1045,1728,1010,1731,955,1733,909,1719,878,1689,849,1616",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 40,
    title: "Back Left Thigh",
    coords:
      "830,1668,824,1686,776,1724,733,1735,669,1735,623,1719,586,1693,553,1659,526,1614,516,1668,516,1801,513,1875,513,1929,523,2024,522,2073,522,2112,517,2164,502,2246,565,2166,593,2231,615,2206,627,2234,657,2322,699,2234,750,2005,784,1917,811,1830,827,1766",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 41,
    title: "Back Right Thigh",
    coords:
      "869,1672,866,1717,867,1757,872,1805,884,1866,897,1915,922,2000,930,2046,937,2100,948,2151,955,2218,975,2270,988,2306,997,2331,1019,2276,1042,2213,1067,2236,1101,2178,1116,2194,1153,2243,1142,2128,1148,2021,1168,1844,1179,1744,1168,1608,1130,1675,1083,1714,1046,1727,951,1735,906,1717,876,1689",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 42,
    title: "Left Calf",
    coords:
      "505,2248,563,2171,592,2233,615,2208,656,2323,642,2368,639,2423,636,2497,624,2570,598,2648,559,2731,537,2803,523,2865,429,2865,429,2737,422,2630,426,2515,441,2420",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 43,
    title: "Right Calf",
    coords:
      "998,2332,1010,2365,1010,2447,1004,2503,1016,2570,1034,2643,1070,2724,1103,2877,1200,2874,1200,2778,1215,2587,1216,2496,1200,2397,1182,2339,1153,2244,1103,2180,1067,2233,1042,2216",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 44,
    title: "Back Left Foot",
    coords:
      "532,2962,545,2979,544,3004,538,3020,528,3028,520,3049,519,3076,504,3105,475,3117,422,3114,402,3096,395,3064,384,3038,376,3028,377,3003,382,2988,407,2991,402,2965",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 45,
    title: "Back Right Foot",
    coords:
      "1216,2974,1215,3001,1229,2992,1238,3000,1246,3025,1228,3061,1221,3089,1212,3116,1183,3126,1139,3123,1104,3101,1100,3076,1098,3055,1091,3037,1079,3020,1077,2994,1085,2979",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 46,
    title: "Sacrum",
    coords: "726,1294,806,1350,849,1429,903,1351,976,1299,849,1192",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 47,
    title: "Left Ankle",
    coords:
      "426,2865,526,2864,534,2894,539,2933,534,2961,405,2964,407,2934,423,2892",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 48,
    title: "Back Right Lower Leg",
    coords:
      "1098,2876,1086,2922,1080,2950,1089,2979,1216,2973,1213,2940,1201,2903,1200,2874",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
];

export const FEMALE_FRONT_AREAS = [
  {
    id: 1,
    title: "Front Left Head",
    coords:
      "732,1,736,353,670,342,632,293,624,260,604,255,592,213,592,187,613,168,619,100,633,54,657,26,692,12",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 2,
    title: "Front Right Head",
    coords:
      "733,3,737,355,782,347,826,313,841,263,872,248,877,179,854,175,850,112,835,56,796,17",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 3,
    title: "Front Neck",
    coords:
      "640,306,652,388,644,437,619,466,571,500,519,507,549,527,922,524,954,506,923,507,867,484,838,453,821,424,816,371,825,314,787,345,736,354,670,341",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 4,
    title: "Front Left Shoulder",
    coords:
      "730,525,729,674,670,682,629,659,588,655,491,682,369,669,373,625,386,579,416,536,444,512,490,502,520,510,545,527",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 5,
    title: "Front Right Shoulder",
    coords:
      "732,525,729,673,787,680,820,663,855,655,906,662,973,682,1101,675,1093,612,1069,560,1030,516,990,506,954,506,922,525",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 6,
    title: "Front Left Upper Arm",
    coords:
      "490,683,369,669,368,709,360,775,347,849,322,931,306,963,414,1014,456,918,490,828,483,783,498,738",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 7,
    title: "Front Right Upper Arm",
    coords:
      "980,683,973,729,984,774,979,825,1061,1025,1165,964,1135,870,1102,741,1101,676",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 8,
    title: "Front Left Lower Arm",
    coords:
      "307,964,415,1017,397,1085,210,1426,135,1386,183,1290,206,1208,239,1092",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 9,
    title: "Front Right Lower Arm",
    coords:
      "1064,1023,1168,964,1207,1035,1247,1113,1268,1202,1344,1383,1272,1426,1253,1383,1084,1094",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 10,
    title: "Front Left Hand",
    coords:
      "133,1386,126,1417,47,1453,-1,1513,16,1526,49,1507,66,1484,60,1526,11,1622,13,1641,34,1627,81,1558,78,1589,55,1654,47,1679,62,1684,80,1660,116,1575,122,1583,120,1605,105,1679,106,1690,116,1693,129,1671,154,1581,160,1578,167,1601,168,1658,175,1662,187,1654,189,1575,204,1528,210,1461,211,1428",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 11,
    title: "Front Right Hand",
    coords:
      "1416,1479,1416,1490,1425,1525,1450,1566,1471,1617,1467,1625,1457,1625,1402,1550,1399,1559,1437,1662,1433,1673,1412,1666,1367,1568,1364,1578,1383,1681,1379,1689,1361,1677,1329,1575,1324,1585,1319,1662,1310,1664,1299,1647,1298,1579,1276,1511,1272,1471,1270,1432,1344,1384,1357,1412,1383,1417,1408,1429,1431,1444,1446,1474,1469,1501,1466,1512,1438,1507",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 12,
    title: "Left Chest",
    coords:
      "491,684,500,735,486,786,490,826,511,963,523,1053,520,1101,566,1072,590,1032,600,990,734,912,729,772,728,675,670,682,623,657,587,657",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 13,
    title: "Right Chest",
    coords:
      "729,674,736,909,874,998,875,1048,896,1081,921,1095,946,1094,947,1039,977,825,984,771,972,736,979,684,943,674,868,655,820,662,789,683",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 14,
    title: "Left Abdomen",
    coords:
      "733,913,598,992,589,1036,566,1071,522,1101,501,1155,482,1193,467,1249,480,1283,535,1321,580,1373,733,1370",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 15,
    title: "Right Abdomen",
    coords:
      "734,910,736,1370,897,1367,933,1332,976,1307,1006,1262,1001,1236,983,1183,960,1137,947,1093,921,1097,892,1080,872,1047,871,998",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 16,
    title: "Genitalia",
    coords:
      "581,1374,621,1447,686,1534,722,1584,745,1584,782,1534,849,1445,897,1369",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 17,
    title: "Front Left Thigh",
    coords:
      "466,1244,453,1307,440,1369,437,1423,427,1525,427,1681,440,1788,466,2088,659,2100,673,1897,705,1692,712,1645,712,1622,728,1588,616,1437,573,1361,539,1327,477,1282",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 18,
    title: "Front Right Thigh",
    coords:
      "736,1588,749,1579,901,1362,933,1329,976,1310,1005,1268,1014,1304,1025,1350,1029,1419,1039,1484,1048,1580,1050,1692,1036,2067,841,2100,821,1927,759,1626",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 19,
    title: "Left Shin",
    coords:
      "441,2292,423,2370,420,2578,431,2824,519,2825,545,2695,590,2556,606,2483,613,2409,615,2318",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 20,
    title: "Right Shin",
    coords:
      "897,2307,908,2401,920,2479,952,2585,993,2703,1027,2820,1115,2814,1113,2740,1111,2578,1101,2402,1068,2266",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 21,
    title: "Front Left Foot",
    coords:
      "432,2825,519,2827,526,2888,516,2920,517,2969,508,2991,516,3028,505,3078,484,3089,454,3081,437,3089,374,3068,373,3029,387,3005,418,2919,413,2874",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 22,
    title: "Front Right Foot",
    coords:
      "1026,2821,1023,2877,1032,2908,1036,2947,1034,2970,1043,2990,1039,3021,1044,3054,1060,3081,1096,3072,1106,3078,1180,3063,1181,3021,1163,2987,1142,2954,1112,2876,1115,2838,1114,2815",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 49,
    title: "Left Knee",
    coords:
      "661,2102,659,2158,648,2217,627,2263,617,2317,439,2291,464,2197,464,2087",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 50,
    title: "Right Knee",
    coords:
      "841,2102,1039,2067,1040,2176,1067,2267,895,2308,884,2261,858,2215,847,2169",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
];

export const FEMALE_BACK_AREAS = [
  {
    id: 23,
    title: "Back Left Head",
    coords:
      "738,384,740,39,692,46,649,77,631,108,620,189,621,210,603,202,598,219,608,276,625,297,637,296,657,384",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 24,
    title: "Back Right Head",
    coords:
      "740,382,741,38,789,47,833,72,849,99,862,152,863,218,875,205,880,200,888,213,881,250,863,294,851,297,849,319,843,359,829,381",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 25,
    title: "Back Neck",
    coords:
      "657,384,826,382,828,428,863,469,948,503,1015,512,987,541,489,548,461,519,532,507,616,470,654,428",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 26,
    title: "Back Left Shoulder",
    coords:
      "461,522,426,543,394,581,378,624,369,675,365,745,487,778,566,788,625,771,733,725,732,545,489,550",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 27,
    title: "Back Right Shoulder",
    coords:
      "1018,514,989,542,734,545,734,726,909,785,988,771,1117,734,1117,671,1102,612,1078,561,1050,528",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 28,
    title: "Back Left Upper Arm",
    coords:
      "486,778,365,746,352,804,340,852,303,967,285,1004,395,1051,447,934,490,830",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 29,
    title: "Back Right Upper Arm",
    coords:
      "989,772,988,803,994,835,1078,1052,1190,996,1164,930,1126,792,1118,734",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 30,
    title: "Back Left Lower Arm",
    coords:
      "284,1002,252,1057,231,1109,221,1161,208,1219,188,1317,164,1399,154,1430,223,1468,233,1432,265,1357,313,1261,378,1127,394,1051",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 31,
    title: "Back Right Lower Arm",
    coords:
      "1191,996,1212,1031,1232,1076,1257,1137,1269,1198,1290,1294,1306,1345,1339,1433,1262,1470,1256,1438,1230,1387,1096,1118,1077,1049",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 32,
    title: "Back Left Hand",
    coords:
      "152,1432,225,1467,225,1488,230,1511,226,1558,211,1601,215,1684,209,1692,200,1687,189,1618,184,1608,180,1641,168,1702,158,1715,148,1706,156,1604,147,1605,139,1629,130,1688,120,1700,108,1697,110,1666,121,1595,108,1626,99,1648,81,1654,85,1622,99,1567,106,1549,81,1547,99,1521,97,1490,122,1467,138,1458",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 33,
    title: "Back Right Hand",
    coords:
      "1262,1470,1262,1491,1257,1513,1258,1537,1273,1600,1272,1689,1282,1697,1291,1687,1300,1613,1308,1625,1316,1677,1331,1717,1346,1718,1343,1687,1335,1609,1345,1614,1362,1671,1373,1702,1386,1709,1396,1700,1386,1648,1373,1595,1387,1609,1402,1646,1419,1667,1428,1659,1425,1642,1409,1593,1398,1562,1391,1538,1413,1554,1429,1557,1434,1543,1417,1525,1408,1497,1392,1482,1361,1462,1350,1457,1339,1434",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 34,
    title: "Upper Left Back",
    coords:
      "485,779,519,981,567,993,625,990,683,971,733,940,730,726,632,767,569,789",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 35,
    title: "Upper Right Back",
    coords:
      "733,725,734,942,788,971,851,986,912,988,969,977,990,842,990,771,906,785,839,763",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 36,
    title: "Lower Left Back",
    coords:
      "733,942,683,971,625,992,567,994,518,981,535,1071,539,1132,522,1173,479,1269,519,1245,560,1248,585,1258,610,1274,733,1170",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 37,
    title: "Lower Right Back",
    coords:
      "734,940,733,1172,850,1271,891,1249,926,1233,960,1237,987,1248,1006,1261,990,1206,964,1162,954,1110,958,1065,969,979,920,988,853,988,783,968",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 38,
    title: "Left Hip",
    coords:
      "479,1270,516,1247,556,1248,588,1258,608,1275,674,1328,732,1404,730,1468,738,1589,737,1621,716,1660,686,1689,627,1706,586,1710,536,1704,486,1692,436,1645,441,1518,452,1457,460,1340",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 39,
    title: "Right Hip",
    coords:
      "729,1411,779,1336,850,1274,910,1241,925,1236,958,1235,987,1250,1011,1268,1035,1379,1042,1472,1050,1533,1054,1599,1054,1642,1035,1656,998,1679,954,1701,917,1708,841,1710,782,1683,740,1612",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 40,
    title: "Back Left Thigh",
    coords:
      "437,1643,485,1689,536,1702,590,1710,685,1692,724,1656,724,1681,711,1764,692,1857,679,1933,658,2220,623,2313,569,2194,543,2221,507,2158,470,2225,473,2131",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 41,
    title: "Back Right Thigh",
    coords:
      "778,1680,839,1708,920,1709,962,1698,1011,1672,1054,1643,1051,1701,1043,1776,1030,1888,1023,1983,1017,2086,1011,2226,969,2158,931,2219,912,2194,858,2303,834,2249,822,2201,805,1909,766,1662",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 42,
    title: "Left Calf",
    coords:
      "470,2230,506,2162,544,2224,569,2191,623,2320,616,2380,612,2464,606,2521,588,2605,569,2678,546,2761,527,2854,437,2854,428,2750,418,2594,418,2486,430,2377",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 43,
    title: "Right Calf",
    coords:
      "856,2305,912,2194,930,2219,969,2157,1013,2226,1025,2275,1043,2351,1057,2434,1063,2590,1050,2852,960,2854,917,2693,881,2576,867,2481,863,2373",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 44,
    title: "Back Left Foot",
    coords:
      "531,2949,527,2956,419,2953,420,2970,411,2999,418,3022,420,3047,427,3064,443,3073,481,3076,507,3064,516,3041,518,3011,543,3002,548,2972,544,2957",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 45,
    title: "Back Right Foot",
    coords:
      "962,2947,1067,2946,1072,2961,1085,2966,1090,2972,1090,2991,1084,3009,1069,3025,1064,3047,1044,3068,1018,3071,997,3070,975,3058,965,3037,967,3004,963,2990,959,2967",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 46,
    title: "Sacrum",
    coords: "734,1170,612,1274,677,1335,732,1405,774,1341,850,1273",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 47,
    title: "Left Ankle",
    coords:
      "436,2853,430,2890,420,2930,419,2953,527,2954,533,2923,532,2887,527,2854",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
  {
    id: 48,
    title: "Right Ankle",
    coords:
      "960,2854,1050,2851,1052,2882,1063,2912,1065,2945,962,2946,954,2920,960,2882",
    notesCount: 0,
    notes: [],
    top: 0,
    left: 0,
  },
];

export const SPARK3 = {
  chart: {
    id: "sparkline3",
    group: "sparklines",
    fontFamily: "'Cerebri Sans', sans-serif",
    type: "area",
    height: 160,
    sparkline: {
      enabled: true,
    },
  },
  stroke: {
    curve: "straight",
  },
  fill: {
    opacity: 1,
  },
  series: [
    {
      name: "",
      data: [],
    },
  ],
  labels: [],
  xaxis: {
    type: "datetime",
  },
  yaxis: {
    min: 0,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  colors: ["var(--theme-color,#2c7be5)"],
  title: {
    offsetX: 30,
    style: {
      fontSize: "24px",
    },
  },
  subtitle: {
    offsetX: 30,
    style: {
      fontSize: "14px",
    },
  },
};

export const BAR = {
  chart: {
    type: "bar",
    height: 380,
    width: "100%",
    stacked: true,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "#ccc",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  series: [
    {
      name: "Observed Percentage",
      data: [],
    },
  ],
  labels: [],
  xaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    min: 0,
    labels: {
      formatter: function (value) {
        return +parseFloat(value).toFixed(2) + "%";
      },
    },
  },
  tooltip: {
    followCursor: true,
    x: {
      formatter: function (_, opt) {
        return `<strong>${
          opt.w.config.series[0].data[opt.dataPointIndex].x
        }</strong><br>${opt.w.config.series[0].data[opt.dataPointIndex].label}`;
      },
    },
    intersect: false,
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
  title: {
    text: "General Symptoms Observed",
    align: "left",
    style: {
      fontSize: "18px",
    },
  },
};

export const BAR_HORIZONTAL = {
  chart: {
    type: "bar",
    height: 220,
    width: "100%",
    stacked: true,
    fontFamily: "'Cerebri Sans', sans-serif",
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "50%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "all",
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
  states: {
    normal: {
      filter: { type: "none", value: 0 },
    },
    hover: {
      filter: { type: "darken", value: 0.7 },
    },
    active: {
      filter: { type: "darken", value: 0.5 },
      allowMultipleDataPointsSelection: false,
    },
  },
  grid: {
    row: {
      colors: ["transparent", "transparent"],
      opacity: 0,
    },
    borderColor: "#888",
  },
  colors: ["var(--theme-color,#2c7be5)"],
  series: [
    {
      name: "Observed %",
      data: [],
    },
  ],
  labels: [],
  xaxis: {
    labels: {
      formatter: function (value) {
        return +parseFloat(value).toFixed(2) + "%";
      },
      style: {
        colors: ["#ffffff"],
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: ["#ffffff"],
      },
    },
  },
  tooltip: {
    followCursor: true,
    // x: {
    //   formatter: function (_, opt) {
    //     return `<strong>${
    //       opt.w.config.series[0].data[opt.dataPointIndex].x
    //     }</strong><br>${opt.w.config.series[0].data[opt.dataPointIndex].label}`;
    //   },
    // },
    y: {
      formatter: function (value) {
        return +parseFloat(value).toFixed(2) + "%";
      },
    },
    intersect: false,
  },
  noData: {
    text: "No data is available",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
  },
  title: {
    text: "General Symptoms Observed",
    align: "left",
    style: {
      fontSize: "18px",
      color: "#ffffff",
    },
  },
};
